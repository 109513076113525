<template>
    <v-app-bar
        app
        clipped-left
        v-if="$kc.authenticated"
    >
        <v-icon style="margin-right: 8px;" @click="$app.drawer = true" v-if="$vuetify.breakpoint.mdAndDown">
            mdi-dots-grid
        </v-icon>
        <v-toolbar-title class="pl-6 flex-fill">
            {{ pageTitle }}
        </v-toolbar-title>
        <v-combobox
            v-if="tags.length > 0 && $vuetify.breakpoint.mdAndUp"
            :items="tags"
            v-model="$app.tagFilter"
            multiple
            small-chips
            label="Tag filter"
            dense
            style="margin: 22px 32px 0 0; max-width: 300px;"
            solo-inverted
        >

        </v-combobox>

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="loadAgents"
                >
                    <v-icon>mdi-earth</v-icon>
                </v-btn>
            </template>
            <v-list
                subheader
                style="text-align: left;"
                min-width="240px"
            >
                <v-subheader>Online Agents</v-subheader>
                <v-list-item
                    v-for="(item, index) in agents"
                    :key="index"
                >
                    <v-icon color="#6f9a3b">mdi-check-circle</v-icon> &nbsp;
                    <v-list-item-title>{{ regionData(item).country + '(' + regionData(item).name + ')' }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- Check that the SDK client is not currently loading before accessing is methods -->
        <div>

            <v-menu v-if="$kc.authenticated" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>


                <v-list
                    subheader
                    style="text-align: left;"
                    min-width="240px"
                >
                    <v-list-item>
                        <v-list-item-avatar>
                            <img alt="Profile pic" :src="$userInfo.picture">
                        </v-list-item-avatar>
                        <v-list-item-title>{{ $userInfo.name }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-subtitle>{{ $userInfo.email }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-switch
                        v-model="$app.darkMode"
                        label="Dark Mode"
                    ></v-switch>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                        @click="$app.logout"
                    >
                        <v-icon>mdi-logout</v-icon>
                        <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: 'TopBar',
    components: {},
    props: {
        pageTitle: {
            type: String,
            required: true
        }
    },
    data: () => ({
        regionMapping: {
            "us-east-1": "N. Virginia",
            "ap-southeast-2": "Sydney",
            "ap-northeast-2": "Seoul",
            "eu-central-1": "Frankfurt",
            "sa-east-1": "São Paulo",
            "af-south-1": "Cape Town"
        },
    }),
    computed: {
        ...mapState({
            agents: state => state.agents.items,
        }),
        ...mapGetters({
            tags: 'monitors/tags',
            regionData: 'agents/getRegionData'
        })
    },
    methods: {
        async loadAgents() {
            await this.$store.dispatch('agents/fetch');
        },
        regionToName(region) {
            // eslint-disable-next-line no-prototype-builtins
            return (this.regionMapping.hasOwnProperty(region) ? this.regionMapping[region] : region)
        }
    },
}
</script>

<style lang="scss">

</style>
