import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";
import MonitorLiveView from "../views/MonitorLiveView";
import Services from "../views/Services";
import Account from "../views/Account";
import Login from "../views/Login";
import Logout from "../views/Logout";
import StatusPage from "../views/StatusPage";
import Alerts from "../views/Alerts";

Vue.use(VueRouter);

const routes = [
  { path: '/services', component: Services, meta: { title: 'Service Configuration' }, beforeEnter: authGuard },
  { path: '/alerts', component: Alerts, meta: { title: 'Alert Configuration' }, beforeEnter: authGuard },
  { path: '/account', component: Account, meta: { title: 'Account' }, beforeEnter: authGuard },
  { path: '/statuspages', component: StatusPage, meta: { title: 'Public Status pages Configuration' }, beforeEnter: authGuard },
  { path: '/login', component: Login, meta: { title: 'Login' } },
  { path: '/logout', component: Logout, meta: { title: 'Logout' } },
  { path: '*', component: MonitorLiveView, meta: { title: 'Status Dashboard' } }
]

export default new VueRouter({
//  mode: 'history',
  routes // short for `routes: routes`
});