<template>
    <v-app id="app">
        <TopBar :page-title="pageTitle"></TopBar>
        <v-navigation-drawer app v-if="authenticated" :temporary="$vuetify.breakpoint.mdAndDown"
            mobile-breakpoint="200" touchless bottom v-model="drawer" clipped
            :expand-on-hover="!$vuetify.breakpoint.mdAndDown">
            <v-list dense nav>
                <v-list-item v-for="item in navigation" :key="item.title" link
                    @click="$router.push({ path: item.route })">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid class="login-background" v-if="!authenticated">
                <v-row style="padding-top: 40vh;">
                    <v-col>
                        <div :class="$vuetify.breakpoint.mdAndDown ? 'login-title-mobile' : 'login-title'">LOOKOUTDUTY.COM</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-btn x-large @click="loginInitially"><v-icon>mdi-account</v-icon> LOGIN</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <router-view v-else></router-view>
        </v-main>
        <v-footer app padless v-if="authenticated">
            <v-col cols="2"></v-col>
            <v-col class="text-center" cols="8">
                {{ footerText }}
            </v-col>
            <v-col cols="2">
                <v-icon v-if="pushConnected === true" style="float: right;">mdi-lan-connect</v-icon>
                <v-icon v-else style="float: right;">mdi-lan-disconnect</v-icon>
            </v-col>
        </v-footer>
        <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" :color="snackbarColor" min-width="10px">
            {{ snackbarText }}
        </v-snackbar>
    </v-app>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from "vuex";
import TopBar from "./components/TopBar";
import PushClient from "./lib/PushClient";

export default {
    name: 'App',
    components: {
        TopBar
    },
    data: () => ({
        tagFilter: [],
        lastStatus: {},
        lastLoadTs: null,
        loading: false,
        pushClient: null,
        pushConnected: false,
        drawer: true,
        apiBaseUrl: process.env.VUE_APP_API_BASE,
        hasNotificationPermission: false,
        displayNotificationButton: false,
        showSnackbar: false,
        snackbarText: "",
        pageTitle: 'Home',
        snackbarTimeout: 4000,
        snackbarColor: 'success',
        darkMode: false,
        preventSettingsSave: false,
        settingsSaveCnt: 0,
        navigation: [
            { title: 'Monitor Live View', icon: 'mdi-view-dashboard', route: '/' },
            { title: 'Services', icon: 'mdi-cog', route: '/services' },
            { title: 'Alerts', icon: 'mdi-alert-decagram-outline', route: '/alerts' },
            { title: 'Status Pages', icon: 'mdi-web', route: '/statuspages' },
        ],
    }),
    created() {
        Vue.prototype.$app = this;

        if (process.env.VUE_APP_DEV_MODE === 'true') {
            this.navigation.push({ title: 'Account', icon: 'mdi-account', route: '/account' });
        }

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                this.loadStatuses();
            }
        });
        if (this.$vuetify.breakpoint.mdAndDown) {
            this.drawer = false;
        }

        this.$router.afterEach((to) => {
            // Use next tick to handle router history correctly
            // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
            Vue.nextTick(() => {
                if (to.meta.title) {
                    this.pageTitle = to.meta.title;
                }
            });
        });

        this.pushClient = new PushClient(this.$kc);

        this.pushClient.on('connected', () => {
            console.log("Push connected");
        });
        this.pushClient.on('auth-success', () => {
            this.pushConnected = true;
            console.log("Push authenticated");
        });
        this.pushClient.on('reconnected', () => {
            this.displaySnackbar("Connected", 2);
        });
        this.pushClient.on('closed', () => {
            this.pushConnected = false;
            this.displaySnackbar("Disconnected... reconnecting", 3, true);
        });
        this.pushClient.on('error', () => {
            this.pushConnected = false;
        });
        this.pushClient.on('connection-failed', () => {
            this.pushConnected = false;
            this.loadStatuses();
        });
        this.pushClient.on('message', (jsonData) => {
            switch (jsonData.type) {
                case 'update': {
                    switch (jsonData.resource) {
                        case 'monitors': {
                            const id = jsonData.data.id;
                            const originalItem = this.statuses.find(item => item.id === id);
                            if (!originalItem) {
                                console.error("Could not find item with id " + id + " to update");
                                return;
                            }
                            const item = this.$clone(originalItem);
                            if (item) {
                                const data = this.$clone(jsonData.data);
                                item.alerts = data.alerts;
                                const idx = item.states.findIndex(i => i.agent === data.state.agent);
                                if (idx >= 0) {
                                    item.states[idx] = data.state;
                                } else {
                                    item.states.push(data.state);
                                }
                                this.onUpdateStatusEntry(item, jsonData.data.agent);
                                console.log(item);
                                this.$store.dispatch('monitors/updateLocal', { item: item });
                            } else {
                                console.error("Could not find item: " + id + " to update!");
                                this.loadStatuses();
                            }
                            break;
                        }
                    }
                }
                    break;
                default:
                    console.error("UNKNOWN type: " + jsonData.type);
            }
        });

    },
    watch: {
        darkMode(val) {
            if (!this.preventSettingsSave) {
                this.saveSetting('darkMode', val);
            }
            this.preventSettingsSave = false;
            this.$vuetify.theme.dark = val;
        },
        /* For some reson that is beyond me, we cannot watch authenticated */
        // authenticated: async (val) => {
        //     if (val) {
        //         const token = this.$kc.token;
        //         const response = await fetch(process.env.VUE_APP_API_BASE + '/login', {
        //             method: 'GET',
        //             headers: {
        //                 Authorization: `Bearer ${token}`
        //             }
        //         });
        //         if (!response.ok) {
        //             console.log(response);
        //             console.error("Login failed!");
        //         } else {
        //             await this.$store.dispatch('account/fetch');
        //             await this.loadStatuses();
        //             this.pushClient.connect();
        //         }
        //     }
        // },
        account(account) {
            this.preventSettingsSave = true;
            this.darkMode = account.settings.darkMode;
        }
    },
    computed: {
        ...mapGetters({
            statuses: 'monitors/sorted',
            groups: 'monitors/groups',
            online: 'monitors/online',
            offline: 'monitors/offline',
            unstable: 'monitors/unstable'
        }),
        ...mapState({
            account: state => state.account.account,
        }),
        footerText() {
            if (!this.lastLoadTs) {
                return 'Loading...';
            }
            return 'Online: ' + this.online.length + (this.unstable.length > 0 ? '(Unstable: ' + this.unstable.length : '') + ' Offline: ' + this.offline.length;
        },
        authenticated() {
            console.log("XXXXXXXXXXXXXXXXXXXXXX Authenticated: " + this.$kc.authenticated);
            console.log(this.$kc);
            if (this.$kc.authenticated) {
                this.onAuthenticated();
            }
            return this.$kc.authenticated;
        }
    },
    methods: {
        async onAuthenticated() {
            if(this.pushConnected) {
                return;
            }
            const token = this.$kc.token;
            const response = await fetch(process.env.VUE_APP_API_BASE + '/login', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) {
                console.log(response);
                console.error("Login failed!");
            } else {
                await this.$store.dispatch('account/fetch');
                await this.loadStatuses();
                this.pushClient.connect();
            }
        },
        async saveSetting(key, val) {
            if (this.settingsSaveCnt > 10) {
                console.error("Settings save overrun...")
                return;
            }
            this.settingsSaveCnt++;
            const data = {};
            data[key] = val;
            const token = this.$kc.token;
            const response = await fetch(process.env.VUE_APP_API_BASE + '/accountsettings', {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                console.log(response);
                console.error("Settings updated!");
            } else {
                await this.$store.dispatch('account/fetch');
            }
        },
        login() {
            this.$router.replace('/login');
        },
        loginInitially() {
            this.$kc.login();
        },
        // Log the user out
        logout() {
            console.log("Logging out...");
            this.pushClient.close();
            this.$router.replace('/logout');
        },
        displaySnackbar(message, timeout, isError) {
            this.snackbarTimeout = timeout ? Math.min(timeout * 1000, 5000) : 4000;
            this.snackbarText = message;
            this.snackbarColor = isError ? "error" : "success";
            this.showSnackbar = true;
        },
        async loadStatuses() {
            this.loading = true;
            await this.$store.dispatch('monitors/fetch');
            await this.$store.dispatch('servicealerts/fetch');
            this.loading = false;
            this.lastLoadTs = new Date();

            this.statuses.forEach(item => {
                this.onUpdateStatusEntry(item);
            });
        },
        onUpdateStatusEntry(item, agent = "unknown-agent") {
            if (!this.lastStatus[item.id]) {
                /* Just add */
                this.lastStatus[item.id] = item.state;
            } else {
                if (this.lastStatus[item.id] !== item.state) {
                    console.log("Status changed " + this.lastStatus[item.id] + " => " + item.state);
                    switch (this.lastStatus[item.id]) {
                        case 'ONLINE':
                            this.displayStatusChange(item.name + "@" + agent, true);
                            break;
                        case 'OFFLINE':
                            this.displayStatusChange(item.name + "@" + agent, false);
                            break;
                    }
                }
                this.lastStatus[item.id] = item.state;
            }
        },
        displayStatusChange(service, offline) {
            if (this.hasNotificationPermission) {
                const img = '/bell-128.png';
                console.log(img);
                const text = 'Service: ' + service + ' is ' + (offline ? 'OFFLINE' : 'back ONLINE');
                new Notification('Status Service', { body: text, icon: img });
            } else {
                this.displaySnackbar('Service: ' + service + ' is ' + (offline ? 'OFFLINE' : 'back ONLINE'), 5, offline);
            }
        }
    },
}
</script>

<style lang="scss">
#app {
    font-family: 'lato', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.login-background {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;

    .login-title {
        left: 0;
        right: 0;
        color: #FFF;
        text-align: center;
        font-family: 'lato', sans-serif;
        font-weight: 300;
        font-size: 60px;
        letter-spacing: 10px;
    }

    .login-title-mobile {
        left: 0;
        right: 0;
        color: #FFF;
        text-align: center;
        font-family: 'lato', sans-serif;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 4px;
    }
}
</style>
