// initial state
const state = {
    pre: [
        {
            label: 'Default',
            key: 'default'
        }
    ],
    post: [
        {
            label: 'macOS/Safari',
            key: 'macos-safari'
        },
        {
            label: 'iOS/Safari',
            key: 'ios-safari'
        },
        {
            label: 'Windows/InternetExplorer',
            key: 'windows-msie'
        }
    ],
    operatingSystems: [
        {
            label: 'Windows',
            key: 'windows'
        },
        {
            label: 'macOS',
            key: 'macos'
        },
        {
            label: 'iOS',
            key: 'ios'
        },
        {
            label: 'Linux',
            key: 'linux'
        },
        {
            label: 'Android',
            key: 'android'
        },
    ],
    browsers: [
        {
            label: 'Chrome',
            key: 'chrome'
        },
        {
            label: 'Firefox',
            key: 'firefox'
        },
        {
            label: 'InternetExplorer',
            key: 'msie'
        },
        {
            label: 'Edge',
            key: 'edge'
        }
    ]
}

// getters
const getters = {
    getAll: (state) => {
        const result = [];
        state.operatingSystems.forEach(os => {
            state.browsers.forEach(browser => {
                result.push({
                    label: os.label + '/' + browser.label,
                    key: os.key + '-' + browser.key
                });
            });
        });
    return JSON.parse(JSON.stringify(state.pre)).concat((result.concat(state.post)).sort((item1, item2) => item1.label.localeCompare(item2.label)));
    },
    labelByKey: (state, getters) => (key) => {
        const item = getters.getAll.find(item => item.key === key);
        return item ? item.label : '';
    }
}

// actions
const actions = {
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};