
export const authGuard = (to, from, next) => {

  // If the user is authenticated, continue with the route
  if (window.kc.authenticated) {
    console.log('User is authenticated');
    return next();
  }

  // Otherwise, log in
  window.kc.login({
    redirectUri: window.location.origin + to.fullPath
  });
};
