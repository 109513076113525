import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                appNavColor: '#779988',
                secondary: '#ddeacf',
                accent: '#009688',
                error: '#f44336',
                warning: '#9c27b0',
                info: '#3f51b5',
                success: '#4caf50',
                toolbar: '#ffffff',
                background: '#ffffff',
                text: '#000000',
                scrollbarThumb: '#b2b4b5',
                scrollbarThumbHover: '#939596'
            }
        }
    }
});
