import Vue from 'vue';
const apiBaseUrl = process.env.VUE_APP_API_BASE;

// initial state
const state = {
    account: {},
    fetching: false,
    error: ''
}

// getters
const getters = {
    lastLogin: (state) => {
        return state.account;
    }
}

// actions
const actions = {
    async fetch({commit}) {
        if (state.fetching) {
            return;
        }

        commit('fetching');

        if(Vue.prototype.$kc.authenticated) {
            const token = Vue.prototype.$kc.token;

            const response = await fetch(apiBaseUrl + '/account', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            commit('fetchSuccess', (await response.json()).account);

            return response;
        } else {
            commit('fetchError', { message: 'Not authenticated' });
        }
    },
}

// mutations
const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchError(state, response) {
        state.error = response.message;
        state.fetching = false;
        state.account = {};
    },
    fetchSuccess(state, account) {
        state.fetching = false;
        state.account = account;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};