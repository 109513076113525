import Vue from 'vue';
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import App from './App.vue'
import Keycloak from 'keycloak-js';
import { url, clientId, realm } from "../auth_config.json";

Vue.config.productionTip = false
Vue.prototype.$clone = (item) => JSON.parse(JSON.stringify(item));

Array.update = (list, item, idField) => {
    const index = list.findIndex(obj => obj[idField] === item[idField]);

    if (index > -1) {
        Vue.set(list, index, item);
        return list[index];
    } else {
        list.push(item);
        return null;
    }
};


window.kc = Vue.prototype.$kc = new Keycloak({
    url: url,
    realm: realm,
    clientId: clientId,
});

//Vue.prototype.$kc.enableLogging = true;

Vue.prototype.$kc.onTokenExpired = () => {
    console.log('Token expired');
    Vue.prototype.$kc.updateToken(300).then((refreshed) => {
        if (refreshed) {
            console.log('Token refreshed');
        } else {
            console.log('Token not refreshed, valid for another 300 seconds');
        }
    }
    ).catch(() => {
        console.log('Failed to refresh token');
    });
};
Vue.prototype.$userInfo = { };

Vue.prototype.$kc.init({
    onLoad: 'check-sso', // 'check-sso', 'login-required'
    enableLogging: true,
    checkLoginIframe: false,
    useNonce: false // WHY?????????????????? Does not work if enabled
    //checkLoginIframe: false

}).then((authenticated) => {
    console.log('Keycloak initialized ------------------------------------------------------------------------------------------ ');
    if (authenticated) {
        console.log('User is authenticated');
        //console.log("Token: " + Vue.prototype.$kc.token);
        console.log(Vue.prototype.$kc);
        Vue.prototype.$kc.loadUserInfo().then((userInfo) => {
            Vue.prototype.$userInfo = { picture: null, ...userInfo };
            console.log('User info: ' + JSON.stringify(userInfo));
        }).catch((e) => {
            console.log('Failed to load user info');
            console.log(e);
        }).finally(() => {
            console.log('Finally');
            //router.push(window.location.pathname);
        });

    } else {
        console.log('User is not authenticated');
        Vue.prototype.$kc.login();
        //        router.push('/login');
    }
}).catch((e) => {
    console.log('Failed to initialize XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXxx');
    console.log(e);
    console.log(Vue.prototype.$kc);
    // TODO: Handle error
    Vue.prototype.$kc.login();
});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
