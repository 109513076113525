import Vue from 'vue';
const apiBaseUrl = process.env.VUE_APP_API_BASE;

// initial state
const state = {
    items: [],
    regionMapping: {
        "us-east-1": {
            name: "N. Virginia",
            country: 'US East',
            iso: ["US"],
            color: '#008ffb'
        },
        "ap-southeast-2": {
            name: "Sydney",
            country: 'Australia',
            iso: ["AU"],
            color: '#00e396'
        },
        "ap-northeast-2": {
            name: "Seoul",
            country: 'Northeast Asia',
            iso: ["KR", "KP", "CN"],
            color: '#feb019'
        },
        "eu-central-1": {
            name: "Frankfurt",
            country: 'Central Europe',
            iso: ["DE", "PL", "CZ", "AT"],
            color: '#775dd0'
        },
        "sa-east-1": {
            name: "São Paulo",
            country: 'Brazil',
            iso: ["BR"],
            color: '#ff4560'
        },
        "af-south-1": {
            name: "Cape Town",
            country: 'South Africa',
            iso: ["ZA"],
            color: '#2c702d'
        }
    },
    fetching: false
}

// getters
const getters = {
    getRegionData: (state) => (region) => {
        if(Object.prototype.hasOwnProperty.call(state.regionMapping, region)) {
            return state.regionMapping[region];
        }
        return {
            name: "Unknown",
            country: 'Unknown',
            iso: [],
            color: '#999'
        };
    }
}

// actions
const actions = {
    async fetch({commit}) {
        if (state.fetching) {
            return;
        }

        commit('fetching');

        if(Vue.prototype.$kc.authenticated) {
            const token = Vue.prototype.$kc.token;

            const response = await fetch(apiBaseUrl + '/agents', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            commit('fetchSuccess', (await response.json()).items);

            return response;
        } else {
            commit('fetchError', { message: 'Not authenticated' });
        }
    }
}

// mutations
const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchError(state, response) {
        state.error = response.message;
        state.fetching = false;
        state.items = [];
    },
    fetchSuccess(state, items) {
        state.fetching = false;
        state.items = Array.isArray(items) ? items : [];
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};