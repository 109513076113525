import Vue from 'vue';
import Vuex from 'vuex';

import agents from "./modules/agents";
import monitors from "./modules/monitors";
import services from "./modules/services";
import account from "./modules/account";
import alerts from "./modules/alerts";
import userAgents from "./modules/userAgents";
import servicealerts from "./modules/servicealerts";
import statuspages from "./modules/statuspages";

Vue.use(Vuex);

export default  new Vuex.Store({
    modules: {
        agents: agents,
        monitors: monitors,
        services: services,
        account: account,
        alerts: alerts,
        userAgents: userAgents,
        servicealerts: servicealerts,
        statuspages: statuspages
    },
    // getters: getterObject
});
